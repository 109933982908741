import React from "react";
import { makeStyles } from "@material-ui/core/styles";
//import Alert from "@material-ui/lab/Alert";
import { TextField, Typography } from "@material-ui/core";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
// import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import '../../assets/responsivetable/SuperResponsiveTableStyle.css'
// import TableContainer from "@material-ui/core/TableContainer"
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid"
import Autocomplete from "@material-ui/lab/Autocomplete";
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem"
import Divider from "@material-ui/core/Divider"
import Moment from "react-moment";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
// import IconButton from "@material-ui/core/IconButton"
// import ClearIcon from '@material-ui/icons/Clear';
import moment from 'moment'
// *** Redux
import { useDispatch } from 'react-redux';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

import { useEffect } from "react"
// import { useConfirm } from 'material-ui-confirm';

// *** Zopa components
// import { computeChosenCourse } from "../../services/services/OrderActions";
import { getSessionProducts, getHostingActiveProducts, getTranslationProducts } from "../../services/CommonProduct/CommonProductActions";

import {
  setReduxRegistration, setIsRegistrationValidated, setReduxLoading, courseChange, setCourseHasChanged, setReduxinputsChanged
} from "../../services/services/RegistrationActions"
// import { getProduct } from "../../services/services/ProductActions"
// import { getOpenMealsForADayRange } from "../../services/services/RestaurantActions"

// *** Styling

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  table: {
    minWidth: 650
  }
}));

/**
 * The needs 
 * @param {} props 
 * @returns 
 */
export default function NeedsForm(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  // const confirm = useConfirm();
  const { t, i18n } = useTranslation();

  const [localregistration, setlocalregistration] = React.useState(null);
  const [rerender, setRerender] = React.useState(false);

  // The language chosen by the member
  //**  The list of language for a configured for a session
  const [translationlanguagetypelist, settranslationlanguagetypelist] = React.useState([]);
  const translationlanguagetypes = getTranslationProducts();

  // const [translationlanguage, settranslationlanguage] = React.useState(null);

  // const registrationError = useSelector(state => state.registrationReducer.registrationError, shallowEqual);
  // *** not directly used but usefull for hosting, session and mels product
  const products = useSelector(state => state.commonProductReducer.products, shallowEqual);
  const registration = useSelector(state => state.registrationReducer.registration, shallowEqual);
  //  const isSessionFull = useSelector(state => state.registrationReducer.isSessionFull, shallowEqual);
  const openMealsForADayRangeValues = useSelector(state => state.restaurantReducer.openMealsForADayRangeValues, shallowEqual);
  const mealTypeproducts = useSelector(state => state.globalListsReducer.mealtypes, shallowEqual);
  const courseHasChanged = useSelector(state => state.registrationReducer.courseHasChanged, shallowEqual);

  const reduxloading = useSelector(state => state.registrationReducer.reduxloading, shallowEqual);
  const hostingproducts = getHostingActiveProducts();
  const sessionProducts = getSessionProducts();

  // *** Only used to rerender Autocomplete
  const [localMealType, setlocalMealType] = React.useState([]);
  const [localHosting, setlocalHosting] = React.useState([]);
  const [localtranslationlanguage, setlocaltranslationlanguage] = React.useState([]);

  const [mealTypeIsHidden, setMealTypeIsHidden] = React.useState(false);
  const [hostingTypeIsHidden, setHostingTypeIsHidden] = React.useState(false);

  // const isMobile = navigator.userAgent.toLowerCase().match(/mobile/i);
  // *** Styles
  const styles = (theme) => ({
    root: {
      background: "linear-gradient(45deg, #F4F6F6 30%, #F2F3F4 90%)",
      padding: theme.spacing(4),
    },
    details: {
      display: "flex",
    },
  });


  // *** If the number of  hostingtypes is less than 2, we hide the combo box
  useEffect(() => {
    if (hostingproducts)
      if (hostingproducts.length <= 1) {
        setHostingTypeIsHidden(true);
      } else {
        setHostingTypeIsHidden(false);
      }
  }, [hostingproducts]);



  // *** If the number of mealtypes is less than 2, we hide the combo box
  useEffect(() => {
    if (mealTypeproducts)
      if (mealTypeproducts.length <= 1) {
        setMealTypeIsHidden(true);
      } else {
        setMealTypeIsHidden(false);
      }
  }, [mealTypeproducts]);


  useEffect(() => {
    if (courseHasChanged) {
      if (registration) {

        let translist = [];
        let translistout = [];

        if (registration.product) {
          settranslationlanguagetypelist([]);

          // *** Set translation language list
          if (registration.product.array_options.options_sta_traslatelanguages) {
            translist = registration.product.array_options.options_sta_traslatelanguages.split(',');
            translist.forEach((translang) =>
              translistout.push(translationlanguagetypes.find((langFound) => langFound.id === translang))
            )
            settranslationlanguagetypelist(translistout);
          }
          // *** if there's only 1 mealtype,  
          setmealType(null);
          if (mealTypeproducts.length === 1) {
            setmealType(mealTypeproducts[0]);
          }
          // *** if there's only 1 hosting type,  
          sethostingType(null);
          if (hostingproducts.length === 1) {
            sethostingType(hostingproducts[0]);
          }

        }
      }

      settranslationlanguage(null);
      dispatch(setCourseHasChanged(false));
    }
  }, [courseHasChanged, registration, translationlanguagetypes, dispatch]);

  useEffect(() => {
    if (registration) {
      setlocalregistration(registration);
    }
  }, [registration,]);


  function settranslationlanguage(value) {
    registration.translationlanguage = value;
    setlocaltranslationlanguage(value);
    dispatch(setReduxinputsChanged(true));
  }

  function sethostingType(value) {
    registration.hostingType = value;
    setlocalHosting(value);
    dispatch(setReduxinputsChanged(true));
  }
  function setmealType(value) {
    registration.mealType = value;
    setlocalMealType(value);
    dispatch(setReduxinputsChanged(true));
  }

  /* function setmealType(value) {
    registration.mealType = value;
    setlocalMealType(value);
  } */
  /**
  * Handle user inputs
  * @param {*} event 
  */
  function handleInputChange(event, newValue) {

    if (!event.target) {
      console.log("Handleinput change : event.target null");
      return;
    }

    // let test = translationlanguagetype;
    if (event.target) {
      setlocalregistration((prevState) => {

        if (event.target.name === 'tabBusyParticipation')
          prevState.tabBusyParticipation[event.target.value] = !prevState.tabBusyParticipation[event.target.value];
        if (event.target.name === 'tabBusyBreakfast')
          prevState.tabBusyBreakfast[event.target.value] = !prevState.tabBusyBreakfast[event.target.value];
        if (event.target.name === 'tabBusyLunch')
          prevState.tabBusyLunch[event.target.value] = !prevState.tabBusyLunch[event.target.value];
        if (event.target.name === 'tabBusyDiner')
          prevState.tabBusyDiner[event.target.value] = !prevState.tabBusyDiner[event.target.value];
        if (event.target.name === 'tabBusyHosting') {
          if (event.target.value === '0')
            prevState.tabBusyHosting[0] = !prevState.tabBusyHosting[0];
          else
            if (parseInt(event.target.value) === prevState.tabBusyHosting.length - 2)
              prevState.tabBusyHosting[prevState.tabBusyHosting.length - 2] = !prevState.tabBusyHosting[prevState.tabBusyHosting.length - 2];
            else
              for (let i = 1; i < prevState.tabBusyHosting.length - 2; ++i)
                prevState.tabBusyHosting[i] = !prevState.tabBusyHosting[i];
        }
        /*         if (event.target.name === undefined) {
                  if ((event.target.id).substring(0, 8) === "mealType")
                    prevState.mealType = newValue;
                  if ((event.target.id).substring(0, 11) === "hostingType")
                    prevState.hostingType = newValue;
                  if ((event.target.id).substring(0, 19) === "translationlanguage")
                    prevState.translationlanguage = newValue;
                }*/
        return prevState
      });
    }

    dispatch(setReduxRegistration(localregistration));
    dispatch(setIsRegistrationValidated(false));
    //*** Used to force re-render  */
    setRerender(!rerender);
  }

  /**
   * The course has changed, we have to build the registration structure with this new course 
   * @param  productTemp 
   * @param {*} checkDefaultState 
   */
  function chosenCourseChanged(productTemp, checkDefaultState) {

    dispatch(setReduxLoading(true));

    if (typeof productTemp === "object" && productTemp) {
      setlocalregistration({});
      dispatch(setReduxRegistration({}));

      dispatch(courseChange(productTemp, checkDefaultState));
      dispatch(setReduxLoading(false));
    }
  }

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={reduxloading} >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Grid container alignItems="flex-start" spacing={1} style={{ marginTop: "10px", marginBottom: "-10px" }}>
        <Grid item md={12}>
          <Typography variant="caption">
            {(t('needs.help'))}

          </Typography>
        </Grid>
      </Grid>

      <Grid container item md={12} xs={12}
        direction="row-reverse"
        justify="center"
        alignItems="center" spacing={1} style={{ marginTop: "0px" }}>
        {/*        {isSessionFull && <Alert severity="error">Les inscriptions pour ce stage sont complètes. Vous pouvez contacter l'accueil si vous souhaitez être mis en liste d'attente.</Alert>}
 */}        {/* {isMobile && <Alert severity="warning">Dans cette partie, il est préférable d'utiliser votre mobile en mode paysage.</Alert>}
 */}
        <Grid item md={12} xs={12}>
        </Grid>
        <Autocomplete
          id="combo-box-demo"
          fullWidth
          disableClearable
          options={sessionProducts}
          getOptionLabel={(sessionproduct) => moment(new Date(sessionproduct.array_options.options_sta_datedebut * 1000)).format('DD/MM') + " au " + moment(new Date(sessionproduct.array_options.options_sta_datefin * 1000)).format('DD/MM') + " - " + sessionproduct.label}
          onChange={(event, newValue) => {
            chosenCourseChanged(newValue, false);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              id="productId"
              name="productId"
              label={t('needs.choosecourse')}
              variant="outlined"
              fullWidth
            />
          )}
        />

        {/* // *** Start needs display  !isSessionFull*/}
        {registration && registration.tabBusyLigneColonne &&
          <React.Fragment>
            {/*             <div style={{ overflowX: 'auto', overflowY: 'auto' }}>
              <TableContainer component={Paper} sx={{ width: 400 }}>
 */}

            <Grid container style={{ marginTop: 10, backgroundColor: "#FFFEFF" }}>
              <Grid spacing={2} item md={12} xs={12}>
                <Paper style={{ padding: 10 }}>
                  {/* *************************************************************************** */}
                  {/* ********************************************************************** */}


                  <Table >
                    {/* // *** Days date*/}
                    <Thead><Tr >
                      <Th><Typography style={{ fontWeight: 600 }} ></Typography></Th>
                      {
                        registration.tabBusyLigneColonne.map((dateLine, index) => (
                          <Th key={index} style={{ textAlign: "center", verticalAlign: "bottom" }}>
                            <Typography style={{ fontWeight: 600 }}>
                              {index === 0 ? <span>{t('needs.daybefore')}</span> :
                                index === registration.tabBusyLigneColonne.length - 1 ? <span>{t('needs.dayafter')}</span> :
                                  <Moment format="DD/MM">
                                    {dateLine}
                                  </Moment>
                              }
                            </Typography>
                          </Th>))}
                    </Tr>
                    </Thead>


                    {/* // *** Participation !registration.product.array_options.options_sta_fullattend && */}
                    <Tbody>
                      {<Tr >
                        <Td style={{ textAlign: "left", verticalAlign: "middle", borderBottom: '1px solid #CACAC8' }}><Typography style={{ fontWeight: 600 }}>{t('needs.attending')}</Typography></Td>
                        {
                          registration.tabBusyParticipation.map((participation, index) => (
                            <Td key={"part" + index} style={{ verticalAlign: "middle", fontWeight: 600, borderBottom: '1px solid #CACAC8' }}>
                              <Checkbox
                                checked={participation}
                                onChange={handleInputChange}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                name={"tabBusyParticipation"}
                                value={index}
                                color="default"

                                disabled={participation === null || registration.product.array_options.options_sta_fullattend === '1'}
                              />
                            </Td>))}
                      </Tr>}

                      {/* // *** Breakfast */}
                      <Tr >
                        <Td style={{ textAlign: "left", borderBottom: '1px solid #CACAC8' }}><Typography style={{ fontWeight: 600 }} >{t('needs.breakfast')}</Typography></Td>
                        {
                          registration.tabBusyBreakfast.map((breakfast, index) => (
                            <Td key={"break" + index} style={{ fontWeight: 600, borderBottom: '1px solid #CACAC8' }} >
                              <Checkbox
                                checked={breakfast}
                                onChange={handleInputChange}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                name={"tabBusyBreakfast"}
                                value={index}
                                color="default"
                                disabled={(openMealsForADayRangeValues && openMealsForADayRangeValues[1] && openMealsForADayRangeValues[1][index + 1] > 0)}
                              />
                            </Td>))}
                      </Tr>

                      {/* // *** Lunch */}
                      <Tr >
                        <Td style={{ textAlign: "left", borderBottom: '1px solid #CACAC8' }}><Typography style={{ fontWeight: 600 }} >{t('needs.lunch')}</Typography>
                        </Td>
                        {
                          registration.tabBusyLunch.map((lunch, index) => (
                            <Td key={"lunc" + index} style={{ fontWeight: 600, borderBottom: '1px solid #CACAC8' }}>
                              <Checkbox
                                checked={lunch}
                                onChange={handleInputChange}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                name={"tabBusyLunch"}
                                value={index}
                                color="default"
                                disabled={(openMealsForADayRangeValues && openMealsForADayRangeValues[2] && openMealsForADayRangeValues[2][index + 1] > 0)}
                              />
                            </Td>))}
                      </Tr>

                      {/* // *** Diner */}
                      <Tr >
                        <Td style={{ textAlign: "left", borderBottom: '1px solid #CACAC8' }}><Typography style={{ fontWeight: 600 }}>{t('needs.diner')}</Typography>
                        </Td>
                        {
                          registration.tabBusyDiner.map((diner, index) => (
                            <Td key={index} style={{ borderBottom: '1px solid #CACAC8' }}>
                              <Checkbox
                                checked={diner}
                                onChange={handleInputChange}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                name={"tabBusyDiner"}
                                value={index}
                                color="default"
                                disabled={(openMealsForADayRangeValues && openMealsForADayRangeValues[3] && openMealsForADayRangeValues[3][index + 1] > 0)}
                              />
                            </Td>))}
                      </Tr>

                      {/* // *** Hosting */}
                      {registration.tabBusyHosting && <Tr >
                        <Td style={{ textAlign: "left" }} ><Typography style={{ fontWeight: 600 }}>{t('needs.accomodation')}</Typography>
                        </Td>
                        {
                          registration.tabBusyHosting.map((hosting, index) => (
                            <Td key={index} style={{ fontWeight: 600 }}>
                              <Checkbox
                                checked={hosting}
                                onChange={handleInputChange}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                name={"tabBusyHosting"}
                                value={index}
                                color="default"
                                disabled={registration.sta_hostingclosed === '1' || hosting === null}
                              />
                            </Td>))}
                      </Tr>}
                    </Tbody>
                  </Table>
                </Paper>
              </Grid>
            </Grid>

            {/* mealtype and hosting type choice*/}
            {(!(hostingTypeIsHidden && mealTypeIsHidden)) &&
              <Grid container style={{ marginTop: '10px', backgroundColor: "#FFFEFF" }}>
                <Grid spacing={2} item md={12} xs={12}>
                  <Paper style={{ marginTop: "20px", padding: "10px" }}>
                    <Grid container spacing={1} style={{ marginTop: "20px", paddingTop: "0px" }}>
                      <Grid item md={6} xs={12}>
                        {!hostingTypeIsHidden && <Autocomplete
                          id="hostingType"
                          // value={registration.hostingType}
                          // value={hostingproducts.find((option) => option.value === registration.hostingType)}
                          value={localHosting}
                          options={hostingproducts}
                          getOptionLabel={(option) => option.label}
                          // style={{ marginTop: "10px" }}
                          name="hostingType"
                          variant="outlined"
                          onChange={(event, newValue, reason) => {
                            sethostingType(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Choisir un type d'hébergement"
                              name="hostingType"
                              fullWidth
                            />
                          )}
                        />}
                      </Grid>

                      <Grid item md={6} xs={12}>
                        {!mealTypeIsHidden && <Autocomplete
                          id="mealType"
                          options={mealTypeproducts}
                          value={localMealType}
                          getOptionLabel={(option) => option.label}
                          name="mealType"
                          variant="outlined"
                          onChange={(event, newValue) => {
                            setmealType(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Choisir un type de repas"
                              name="test2"
                              fullWidth
                            />
                          )}
                        />}
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            }

            {/* /** Translations   */}
            {translationlanguagetypelist.length > 0 &&
              <Grid container style={{ marginTop: 10, backgroundColor: "#FFFEFF" }}>
                <Grid spacing={2} item md={12} xs={12}>
                  <Paper style={{ marginTop: "10px", padding: "10px" }}>
                    <Grid container spacing={1} style={{ marginTop: "10px", paddingTop: "0px" }}>
                      <Grid item md={6} xs={12} style={{ texAlign: "left" }}>
                        <Typography align='left' >
                          <b>{t('needs.translationtitle')} </b><br />
                          {t('needs.translation')}</Typography>
                      </Grid>
                      <Grid item md={6} xs={12} >
                        <Typography>{t('needs.translationlanguage')}</Typography>
                        <Autocomplete
                          id="translationlanguage"
                          value={localtranslationlanguage}
                          options={translationlanguagetypelist}
                          getOptionLabel={(option) => option.label}
                          onChange={(event, newValue) => {
                            settranslationlanguage(newValue);
                          }}
                          name="translationlanguage"
                          variant="outlined"
                          // onChange={handleInputChange}
                          renderInput={(params) => (
                            <TextField variant="outlined"
                              {...params}
                              label={t('needs.translationlanguage')} />)}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>}
            <Divider />
            {/*              </TableContainer>
            </div>
 */}          </React.Fragment>}
      </Grid >
    </React.Fragment >
  )
};